(function ($) {

    $.fn.jsHelpers = function (method) {
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        } else {
            $.error('Метод ' + method + ' не существует');
            return false;
        }
    };

    var methods = {

        addStylesToHead: function (styles) {
            if (!styles) return;

            var existingStyles = $('link[href]');

            var cb = function (next) {
                var href = this.href;

                var matchedStyles = existingStyles.filter(function () {
                    return this.href === href;
                })

                if (matchedStyles.length) {
                    next();
                    return;
                }

                $('head').append(this);
                next();

            }

            var i = 0;
            var next = function () {
                if (i >= styles.length) {
                    return;
                }
                var style = styles[i];
                i++;
                cb.call(style, next);
            }
            next();
        },
        /**
         * Взял кусок из jquery.pjax
         * (выполняет скрипты переданные параметром, пропуская те которые уже присутствуют на странице)
         *
         * Load an execute scripts using standard script request.
         * Avoids jQuery's traditional $.getScript which does a XHR request and globalEval.
         *
         * scripts - jQuery object of script Elements
         * context - jQuery object whose context is `document` and has a selector
         *
         * Returns nothing.
         */
        executeScriptTags: function (scripts, context) {
            if (!scripts) return

            var existingScripts = $('script[src], script[data-key]');

            var cb = function (next) {
                var src = this.src;
                var key = $(this).data('key');

                var matchedScripts = existingScripts.filter(function (i, s) {
                    let result = (src != '' && this.src === src) || (key != undefined && $(s).data('key') === key);
                    return result;
                });

                if (matchedScripts.length) {
                    next();
                    return;
                }

                if (src) {
                    $.getScript(src).done(next).fail(next);
                    context.append(this);
                } else {
                    context.append(this);
                    next();
                }
            }

            var i = 0;
            var next = function () {
                if (i >= scripts.length) {
                    return;
                }
                var script = scripts[i];
                i++;
                cb.call(script, next);
            }
            next();
        },

        /**
         * Очищаем ответ из аякс, удаляя стили, и выполняя скрипты
         * @param body
         * @returns {*}
         */
        purifyCssJs: function (body) {
            body = `<div><div>${body}</div></div>`; // это объязательно

            let content = $($.parseHTML(body, document, true));
            let scripts = content.filter('script').add(content.find('script')).remove();
            let styles = content.filter('style,link').add(content.find('style,link')).remove();

            content = content.not(scripts);
            content = content.not(styles);

            return {
                content: content.contents().html(),
                scripts: scripts,
                styles: styles
            };
        },

        /**
         * Преобразуем строки 'js:expression' через eval(expression)
         *
         * @param options
         * @returns {*}
         */
        convertJsExpressions: function (options) {

            let deepMap = (obj, cb) => {
                var out = {};

                Object.keys(obj).forEach(function (k) {
                    var val;

                    if (obj[k] !== null && typeof obj[k] === 'object') {
                        val = deepMap(obj[k], cb);
                    } else {
                        val = cb(obj[k], k);
                    }

                    out[k] = val;
                });

                return out;
            }

            return deepMap(options, (value, key) => {
                if (typeof value === 'string' && (value = value.trim()) && value.startsWith('js:')) {
                    return eval('(' + value.substr(3) + ')');
                }
                return value;
            });
        },

        // конвертирует параметры из url в json формат
        parseQueryString: function ( queryString ) {
            var params = {}, queries, temp, i, l;
         
            // Split into key/value pairs
            queries = queryString.split("&");
         
            // Convert the array of strings into an object
            for ( i = 0, l = queries.length; i < l; i++ ) {
                temp = queries[i].split('=');
                params[temp[0]] = temp[1];
            }
         
            return params;
        },

        confirm: function (data, callback) {
            var params = {
                buttons: {
                    confirm: {
                        btnClass: 'btn-primary',
                        text: 'Yes, sure!'
                    },
                    cancel: {
                        text: 'Cancel'
                    }
                }
            };

            if (data.confirmButtonText != undefined) {
                params.buttons.confirm.text = data.confirmButtonText;
            }

            if (data.cancelButtonText != undefined) {
                params.buttons.cancel.text = data.cancelButtonText;
            }

            if (data.confirmTitle != undefined) {
                params.title = data.confirmTitle;
            }

            if (data.confirmContent != undefined) {
                params.content = data.confirmContent;
            }

            if (data.confirmTheme != undefined) {
                params.theme = data.confirmTheme;
            }

            if (data.confirmBackgroundDismiss != undefined) {
                params.backgroundDismiss = data.confirmBackgroundDismiss;
            }

            $.confirm($.extend(true, {}, params, {
                buttons: {
                    confirm: {
                        action: callback
                    }
                }
            }));
        }
    }

    $.ajaxSetup({
        type: 'post',
        beforeSend: function () {

        },
        complete: function (xhr, stat) {

        },
        success: function (data, textStatus, jqXHR) {
            if (typeof data === 'object') {

                if (!data.success) {
                    /**
                     * @todo
                     * Тут надо продумать, и сделать так, чтобы если от js
                     * поучили в ответе, определенный параметр (например: jsCallback), который
                     * бы означал, что надо вызвать функцию
                     */
                    if (data.error != undefined) {
                        var type = data.type != undefined ? data.type : 'alert';
                        var options = $.extend(true, {text: data.error, type: 'error'}, data.options);

                        $.fn.notifications(type, options);
                    }
                }

                // trigger event if present
                if (data.trigger != undefined) {
                    var target = $(document);

                    if (data.trigger.target != undefined) {
                        target = $('#' + data.trigger.target);
                    }

                    if (typeof data.trigger === 'object') {
                        $.each(data.trigger, (i, v) => {
                            target.trigger($.Event(i), [v]);
                        });
                    } else {
                        target.trigger($.Event(data.trigger), [data]);
                    }
                }


            }


        },
        error: function () {

        }
    });

    // сериализация формы в виде json (пропускаем поля с пустымы знаениями)
    $.fn.serializeObject = function() {
        var o = {};
        var a = this.serializeArray();

        $.each(a, function() {
            // случай когда этот ключ есть, тогда надо воспринимать его как массив
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }

            // o[this.name] = this.value || '';
        });
        return o;
    };

    // проиграть аудио файл
    $.fn.playSound = function (filename, path = '/sounds') {
        try {
            var notificationAudio = new Audio();
            notificationAudio.src = path + '/' + filename;
            notificationAudio.volume = 1;
            notificationAudio.play();
        }
        catch (e) {
            console.log(e);
        }
    };

    $.fn.ladda = function (action = false) {
        this.each(function () {
            let ladda = $(this).data('ladda');

            if (ladda != undefined) {
                ladda[action]();
            } else {
                $(this).addClass('btn-ladda btn-ladda-spinner');
                $(this).attr('data-style', 'zoom-in');
                ladda = Ladda.create(this)
                $(this).data('ladda', ladda);

                ladda[action]();
            }
        });
    }

})(jQuery);